<template>
  <nav>
    <v-app-bar height="80" elevation="0">
      <v-toolbar-title class="text--primary"
        >Omnicom MediaGroup</v-toolbar-title
      >

      <v-divider vertical></v-divider>

      <v-toolbar-items class="hidden-sm-and-down ml-16">
        <v-btn
          id="btn"
          class="mx-1"
          active-class="primary"
          v-for="nav in linkItems"
          :key="nav.title"
          :to="nav.link"
          text
          >{{ nav.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <div v-if="bbsInCart">
          <v-badge
            :content="bbsInCart"
            color="primary"
            class="v-toolbar__items__el"
          >
            <v-btn to="/cart" id="cart" text icon>
              <v-icon color="iconFill">mdi-cart </v-icon>
            </v-btn>
          </v-badge>
        </div>
        <v-icon class="v-toolbar__items__el" color="iconFill">mdi-bell </v-icon>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-btn elevation="0" to="/account-details">Account Details</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="logoutClient" elevation="0">
                Logout
                <v-icon
                  title="Logout"
                  class="v-toolbar__items__els"
                  color="secondary"
                  >mdi-logout
                </v-icon></v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <the-date></the-date>
  </nav>
</template>

<script>
import TheDate from "@/layouts/TheDate.vue";

import { mapActions, mapGetters } from "vuex";

import defineAbilityFor from "@/router/guards/abilities";

export default {
  data() {
    return {
      navItems: [
        { title: "Billboards", link: "/billboards", resource: "billboards" },
        { title: "Briefs", link: "/briefs", resource: "briefs" },
        { title: "Campaigns", link: "/campaigns", resource: "campaigns" },
        { title: "Monitoring", link: "/monitoring", resource: "monitoring" },
        {
          title: "Competitive Insights",
          link: "/insights",
          resource: "insights",
        },
      ],
      subMenuItems: [
        { title: "Profile Settings", link: "/briefs" },
        { title: "Logout" },
      ],
    };
  },
  components: { TheDate },

  computed: {
    ...mapGetters({
      getBbCart: "billboards/getBbCart",
      getCurrentUser: "getCurrentUser",
    }),

    bbsInCart() {
      return this.getBbCart.length;
    },
    linkItems() {
      const abilities = defineAbilityFor(this.getCurrentUser?.userType);

      return this.navItems.filter((btn) => {
        return abilities.can("read", btn.resource);
      });
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      fetchBbCart: "billboards/fetchBbCart",
    }),

    async logoutClient() {
      // this.$Tawk.$endChat();
      await this.logout();
      this.$router.replace("/login");
    },
  },
  async mounted() {
    await this.fetchBbCart();
  },
};
</script>
<style scoped>
.v-toolbar__title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;

  /* identical to box height, or 107% */
  letter-spacing: -0.4px;

  /* Primary #1 */
  padding: 0 10px;
}
.v-toolbar__items {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.v-toolbar__items > .v-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.v-btn::before {
  background-color: transparent;
}
.v-toolbar__items__el {
  margin: 7px;
}
.v-btn {
  text-transform: none;
}
#btn {
  height: 40px !important;
  border-radius: 20px !important;
  padding: 8px 16px 8px 16px;
  text-transform: none;
}
#btn:hover {
  background: #ec2327;
  color: #ffffff;
  padding: 8px 16px !important;
  border-radius: 20px;
}
#cart {
  margin: 0;
  height: 24px;
  width: 24px;
}
.news {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
}
</style>
